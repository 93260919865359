<template>
    <div>
        <ts-page-title
            :title="$t('exchangeRate.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('exchangeRate.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <div>
                        <a-button
                            type="primary"
                            @click="addNew"
                            v-if="$can('create-exchange-rates')"
                        >
                            {{ $t("addNew") }}
                        </a-button>
                    </div>
                    <div class="tw-space-x-3">
                        <!-- <a-tooltip :title="$t('driverProfile.search')">
                            <a-input-search
                                v-model="search"
                                :placeholder="$t('driverProfile.search')"
                                style="width: 200px"
                            />
                        </a-tooltip> -->
                    </div>
                </div>
            </ts-panel-wrapper>
            <ts-table
                :columns="columns"
                :records="resources"
                :loading="loading"
            >
                <template v-slot="{ record }">
                    <td>
                        {{ record.dates }}
                    </td>
                    <td>
                        {{ record.currency_code_from }}
                    </td>
                    <td>
                        {{ record.currency_code_to }}
                    </td>
                    <td>
                        {{ record.fx_rate }}
                    </td>
                    <td class="text-center tw-space-x-3">
                        <a
                            v-if="
                                $can('update-exchange-rates') &&
                                    !record.is_deleted
                            "
                            href="#"
                            v-tooltip="$t('edit')"
                            class="text-primary mr-2"
                            @click.prevent="edit(record)"
                        >
                            <i class="fas fa-edit"></i>
                        </a>
                        <a-popconfirm
                            v-if="
                                $can('delete-exchange-rates') &&
                                    !record.is_deleted
                            "
                            placement="leftTop"
                            :title="$t('are_you_sure_to_delete')"
                            :ok-text="$t('yes')"
                            :cancel-text="$t('no')"
                            @confirm="deleteRecord(record)"
                        >
                            <a href="#" :disabled="record._deleting">
                                <i
                                    class="fas fa-circle-notch spin"
                                    v-if="record._deleting"
                                ></i>
                                <i class="fa fa-trash text-red" v-else></i>
                            </a>
                        </a-popconfirm>
                    </td>
                </template>
            </ts-table>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>

        <!-- form-action -->
        <a-modal
            v-if="showForm"
            v-model="showForm"
            :title="$t('exchangeRate.pageTitle')"
            centered
            :maskClosable="false"
            @cancel="clearEdit"
            width="300px"
            :zIndex="1020"
            :footer="null"
        >
            <from-action @cancel="clearEdit" />
        </a-modal>
    </div>
</template>

<script>
import { debounce } from "lodash";
import { mapState } from "vuex";
import FromAction from "./form";

export default {
    name: "exchangeRateIndex",
    components: {
        FromAction
    },
    data() {
        return {
            loading: false,
            showForm: false
        };
    },
    computed: {
        ...mapState("finance/exchangeRate", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.finance.exchangeRate.search;
            },
            set(newValue) {
                this.$store.commit("finance/exchangeRate/SET_SEARCH", newValue);
                this.$store.commit("finance/exchangeRate/RESET_CURRENT_PAGE");
            }
        },
        columns() {
            return [
                { name: this.$t("exchangeRate.date"), sortKey: "dates" },
                { name: this.$t("exchangeRate.fromCurrency") },
                { name: this.$t("exchangeRate.toCurrency") },
                { name: this.$t("exchangeRate.fxRate") },
                {
                    name: this.$t("actions"),
                    style: "text-align:center; width: 1px;"
                }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("finance/exchangeRate/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        addNew() {
            this.showForm = true;
        },
        edit(record) {
            this.$store.commit("finance/exchangeRate/SET_EDIT_DATA", record);
            this.showForm = true;
        },
        clearEdit() {
            this.$store.commit("finance/exchangeRate/SET_EDIT_DATA", {});
            this.showForm = false;
            this.fetchData();
        },
        deleteRecord(record) {
            record._deleting = true;
            this.$store
                .dispatch("finance/exchangeRate/destroy", record.fx_id)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.fetchData();
                })
                .catch(error => {
                    this.$toasted.error(error.message);
                })
                .finally(() => {
                    record._deleting = false;
                });
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("finance/exchangeRate/RESET_STATE");
        next();
    }
};
</script>
