<template>
    <ts-loading-banner :loading="loading">
        <div class="tw-space-y-2">
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("exchangeRate.date")
                    }}</label>
                    <a-date-picker
                        v-model="model.dates"
                        show-time
                        format="DD-MM-YYYY HH:mm"
                        valueFormat="DD-MM-YYYY HH:mm"
                        :placeholder="$t('exchangeRate.date')"
                        class="tw-w-full"
                    />
                    <div class="tw-text-red-600" v-if="errors.has('dates')">
                        {{ errors.first("dates") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 tw-space-y-1">
                    <label class="required">{{
                        $t("exchangeRate.fromCurrency")
                    }}</label>
                    <input
                        type="text"
                        :value="
                            defaultCurrency ? defaultCurrency.currency_code : ''
                        "
                        class="form-control"
                        readonly
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12 tw-space-y-1">
                    <label class="required">{{
                        $t("exchangeRate.toCurrency")
                    }}</label>
                    <select v-model="model.currency_id_to" class="form-control">
                        <option value="">{{
                            $t("exchangeRate.toCurrency")
                        }}</option>
                        <option
                            v-for="(c, index) in currencies"
                            :key="index"
                            :value="c.currency_id"
                            >{{ c.currency_code }}</option
                        >
                    </select>
                    <div
                        class="tw-text-red-600"
                        v-if="errors.has('currency_id_to')"
                    >
                        {{ errors.first("currency_id_to") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 tw-space-y-1">
                    <label class="required">{{
                        $t("exchangeRate.fxRate")
                    }}</label>
                    <input
                        v-model="model.fx_rate"
                        type="number"
                        step="any"
                        class="form-control"
                        :placeholder="$t('exchangeRate.fxRate')"
                    />
                    <div class="tw-text-red-600" v-if="errors.has('fx_rate')">
                        {{ errors.first("fx_rate") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                    <ts-button
                        class="mr-2"
                        @click.prevent="() => $emit('cancel')"
                    >
                        {{ $t("cancel") }}
                    </ts-button>
                    <ts-button
                        v-if="!isUpdate"
                        outline
                        color="info"
                        @click.prevent="onSave"
                        >{{ $t("saveAddNew") }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        @click.prevent="onSave"
                        >{{ $t("save") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        >{{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { isEmpty } from "lodash";
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import moment from "moment";

export default {
    name: "branchForm",
    data() {
        return {
            loading: false,
            errors: new Errors(),
            model: {
                fx_id: "",
                dates: moment().format("DD-MM-YYYY HH:mm"),
                currency_id_from: "",
                currency_id_to: "",
                fx_rate: ""
            }
        };
    },
    computed: {
        ...mapState("finance/exchangeRate", ["edit_data", "formModels"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        },
        currencies() {
            if (!this.formModels.currency) return [];
            return this.formModels.currency.filter(el => !el.is_default);
        },
        defaultCurrency() {
            if (!this.formModels.currency) return "";
            return this.formModels.currency.find(el => el.is_default);
        }
    },
    created() {
        this.setEditData();
    },
    methods: {
        ...mapActions("finance/exchangeRate", ["getFormViewData"]),
        fetchFormView(control, params) {
            this.loading = true;
            this.getFormViewData({ params: params, control: control })
                .then(() => {
                    this.model.currency_id_from = this.defaultCurrency.currency_id;
                })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async onSave() {
            this.loading = true;
            this.$store
                .dispatch("finance/exchangeRate/store", this.model)
                .then(async response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("fetchData");
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async onSaveAddNew() {
            this.loading = true;
            this.$store
                .dispatch("finance/exchangeRate/store", this.model)
                .then(async response => {
                    this.$notify({ type: "success", text: response.message });
                    this.clearInput();
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async onUpdate() {
            this.loading = true;
            this.$store
                .dispatch("finance/exchangeRate/update", {
                    id: this.model.fx_id,
                    data: this.model
                })
                .then(async response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clearInput() {
            this.model.fx_id = "";
            this.model.dates = moment().format("DD-MM-YYYY HH:mm");
            this.model.currency_id_to = "";
            this.model.fx_rate = "";
        },
        async setEditData() {
            await this.fetchFormView();
            if (!isEmpty(this.edit_data)) {
                this.model.fx_id = this.edit_data.fx_id;
                this.model.dates = this.edit_data.dates;
                this.model.currency_id_from = this.edit_data.currency_id_from;
                this.model.currency_id_to = this.edit_data.currency_id_to;
                this.model.fx_rate = this.edit_data.fx_rate;
            }
        }
    }
};
</script>
